@tailwind base;
@tailwind components;
@tailwind utilities;


:root, .light, .light-theme {
  --blue-1: #fcfdfe;
  --blue-2: #f7f9fe;
  --blue-3: #eef2fb;
  --blue-4: #e2eafc;
  --blue-5: #d4e0f9;
  --blue-6: #c3d2f3;
  --blue-7: #aec0e9;
  --blue-8: #90a8de;
  --blue-9: #597acf;
  --blue-10: #4e6dbf;
  --blue-11: #4b6abb;
  --blue-12: #233155;

  --blue-a1: #0055aa03;
  --blue-a2: #0040e008;
  --blue-a3: #003cc311;
  --blue-a4: #0047e51d;
  --blue-a5: #0148dc2b;
  --blue-a6: #0040cc3c;
  --blue-a7: #0039ba51;
  --blue-a8: #0038b46f;
  --blue-a9: #0033b6a6;
  --blue-a10: #002da3b1;
  --blue-a11: #002c9fb4;
  --blue-a12: #00103adc;

  --blue-contrast: #fff;
  --blue-surface: #f5f8fecc;
  --blue-indicator: #597acf;
  --blue-track: #597acf;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --blue-1: oklch(99.4% 0.0018 266.4);
      --blue-2: oklch(98.2% 0.0063 266.4);
      --blue-3: oklch(96% 0.0132 266.4);
      --blue-4: oklch(93.7% 0.0253 266.4);
      --blue-5: oklch(90.5% 0.0372 266.4);
      --blue-6: oklch(86.3% 0.0492 266.4);
      --blue-7: oklch(80.8% 0.0621 266.4);
      --blue-8: oklch(73.3% 0.0836 266.4);
      --blue-9: oklch(59.5% 0.1355 266.4);
      --blue-10: oklch(55.2% 0.1319 266.4);
      --blue-11: oklch(53.9% 0.1319 266.4);
      --blue-12: oklch(32% 0.0669 266.4);

      --blue-a1: color(display-p3 0.0235 0.349 0.6745 / 0.012);
      --blue-a2: color(display-p3 0.0196 0.2667 0.8784 / 0.032);
      --blue-a3: color(display-p3 0.0078 0.1922 0.6902 / 0.063);
      --blue-a4: color(display-p3 0.0039 0.2549 0.8235 / 0.11);
      --blue-a5: color(display-p3 0.0039 0.2471 0.8078 / 0.161);
      --blue-a6: color(display-p3 0.0039 0.2157 0.7412 / 0.224);
      --blue-a7: color(display-p3 0.0039 0.1804 0.6706 / 0.306);
      --blue-a8: color(display-p3 0.0039 0.1725 0.6549 / 0.416);
      --blue-a9: color(display-p3 0 0.1569 0.6627 / 0.624);
      --blue-a10: color(display-p3 0 0.1373 0.5882 / 0.667);
      --blue-a11: color(display-p3 0 0.1333 0.5725 / 0.679);
      --blue-a12: color(display-p3 0 0.051 0.2039 / 0.851);

      --blue-contrast: #fff;
      --blue-surface: color(display-p3 0.9608 0.9725 0.9922 / 0.8);
      --blue-indicator: oklch(59.5% 0.1355 266.4);
      --blue-track: oklch(59.5% 0.1355 266.4);
    }
  }
}
  
body {
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

.bg-stripe-gradient {
  background: repeating-linear-gradient(
    -55deg,
    #1f8269,
    #1f8269 8px,
    #34a387 8px,
    #34a387 13px
  );
}

.non-draggale {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.spin-wheel-animation {
  transform: rotate(var(--start-rotation-degrees));
}

.spin-wheel-animation.started-spinning {
  animation: spin-wheel var(--start-spinning-time)
      cubic-bezier(0.71, var(--spin-wheel-cb-arg), 0.96, 0.9) 0s 1 normal
      forwards running,
    continueSpin-wheel var(--continue-spinning-time) linear
      var(--start-spinning-time) 1 normal forwards running,
    stopSpin-wheel var(--stop-spinning-time) cubic-bezier(0, 0, 0.35, 1.02)
      var(--stop-spin-time-duration) 1 normal forwards running;
}

@keyframes spin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--start-rotation-degree-complete));
  }
}
@keyframes continueSpin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--start-rotation-degree-complete));
  }
}
@keyframes stopSpin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--final-rotation-degrees));
  }
}
